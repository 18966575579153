<template>
	<div class="pl-store__quote-table-wrap">
		<el-table :data="qouteData" :span-method="arraySpanMethod" border style="width: 100% ">
			<el-table-column prop="goods_name" label="商品名称" width="180">
			</el-table-column>
			<el-table-column prop="production_model" label="商品型号" width="180">
			</el-table-column>
			<el-table-column prop="quantity" label="数量">
			</el-table-column>
			<el-table-column prop="price1" label="折扣单价">
			</el-table-column>
			<el-table-column prop="tax_price1" label="折扣单价(含税)">
			</el-table-column>
			<el-table-column prop="ship_days" label="发货天数">
			</el-table-column>
			<el-table-column prop="discountPrice" label="合计">
			</el-table-column>
			<el-table-column prop="remark" label="备注">
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<div class="btn-box">
						<span @click="operation(0, scope)">修改</span>
						<span @click="operation(1, scope)">删除</span>
					</div>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
export default {
	name: 'quote-table',
	props: {
		tableData: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		qouteData() {
			let arr = this.tableData
			arr.forEach((item, index) => {
				// let obj = item.quotation_info
				// if(!Object.keys(obj).length) {
				//     obj = {price:'没有找到该商品，需要人工客服报价',isNotfound:true,tax_price:'',ship_days:'',total_price:''}
				// }
				if (item.quantity) {
					item.quantity = parseInt(item.quantity)
				}
				let obj = {};
				if (item.is_exist == 2 || !item.goods_discount.some(j => j.ask_price_type == 0 && item.quantity >= j.min && j.max >= item.quantity)) {
					obj = {
						price1: '没有找到该商品，需要人工客服报价',
						isNotfound: true,
						tax_price: '',
						ship_days: '',
						total_price: ''
					}

				} else {
					obj = {isNotfound: false}
				}

				item = Object.assign(item, obj)
			})
			return arr
		}

	},
	methods: {
		arraySpanMethod({row, column, rowIndex, columnIndex}) {
			if (row.isNotfound) {
				console.log(row, column, rowIndex, columnIndex)
				if (columnIndex === 3) {
					return [1, 4]
				} else if ([4, 5, 6].indexOf(columnIndex) > -1) {
					return [0, 0]
				}
			}
		},
		operation(type, data) {
			this.$emit('click', type, data.$index, data.row)
		}
	}
}
</script>
<style lang="scss" scoped>
.btn-box {
	color: #D9262C;

	span {
		padding: 8px 10px;
		cursor: pointer;
	}

	span:nth-type-of(2) {
		color: red;
	}


}

.el-table__body:hover {
	background-color: red;
}
</style>
