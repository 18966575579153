<template>
    <div class="pl-store__dialog-wrap">
        <el-dialog :title="title" :visible.sync="dialogFormVisible">
            <el-form :model="form">
                <el-form-item v-for="item in config" :key="item.prop" :label="item.label">
                    <el-input v-model="form[item.prop]" :placeholder="`请输入${item.label}`" :maxlength="item.maxlength"></el-input>
                </el-form-item>
            </el-form>
             <div slot="footer" class="dialog-footer">
            <el-button @click="cancle">取 消</el-button>
            <el-button type="primary" @click="submit" :loading="loading && btnLoading">确 定</el-button>
        </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
        // 用于结构 formData
        config: {
            type: Array,
            default: () => [
                {
                    label: '商品型号',
                    prop:'production_model',
                    require: true,

                },
                {
                    label: '数量',
                    prop:'quantity',
                    require: true
                },
                {
                    label: '备注',
                    prop:'remark',
                    maxlength:250
                }
            ]
        },
        formData: {
            type: Object,
            default: () => { return {}}
        },
        title: {
            type: String,
            default: '修改报价'
        },
        visible: {
            type: Boolean,
            default: false
        },
        // 是否开启按钮loading
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialogFormVisible: this.visible,
            form:Object.assign({}, this.formData),
            btnLoading: false
        }
    },
    watch: {
        formData(val) {
            this.form = Object.assign({}, val)
        },
        visible(v) {
            this.dialogFormVisible = v
        },
        dialogFormVisible(v) {
            this.$emit('update:visible', v)
        },
        loading(v) {
            if(!v) {
                this.btnLoading = false
            }
        }
    },
    methods:{
        submit() {
            if(!this.loading) {
                this.dialogFormVisible = false
            }
            this.$emit('confim', 'submit', this.form)
        },
        cancle() {
            this.dialogFormVisible = false
            this.$emit('confim', 'cancle')
        }
    }
}
</script>
<style lang="scss" scoped>

</style>