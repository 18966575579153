import httpRequst from "../utils/request";
// 快速报价详情
export const quotationConfirm = function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/quotation/confirm`, params)
}
// 提交快速报价
export const quotation = function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/quotation`, params)
}
// 报价单列表
export const getQuotationList = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/quotation`, params)
}
// 报价单详情
export const getQuotation = function(id) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/quotation/${id}`)
}
// 转为订单
export const storeOrder = function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/quotation/store-order`, params)
}
